import React from 'react';

const IconWorldWide = () => (
  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
	 viewBox="0 0 54.438 54.438">
    <title>World Wide</title>
    <path d="M27.219,0C12.21,0,0,12.21,0,27.219s12.21,27.22,27.219,27.22s27.219-12.211,27.219-27.22
	S42.228,0,27.219,0z M47.851,37.852H37.697c0.468-2.775,0.749-5.705,0.837-8.633h11.813C50.082,32.308,49.21,35.224,47.851,37.852z
	 M27.219,50.438c-1.76,0-3.982-3.14-5.52-8.587h11.04C31.201,47.299,28.979,50.438,27.219,50.438z M20.784,37.852
	c-0.465-2.558-0.78-5.448-0.875-8.633h14.619c-0.095,3.185-0.41,6.074-0.875,8.633H20.784z M4.092,29.219h11.812
	c0.088,2.928,0.369,5.858,0.837,8.633H6.588C5.229,35.224,4.357,32.308,4.092,29.219z M6.588,16.587h10.154
	c-0.468,2.775-0.749,5.704-0.837,8.632H4.092C4.357,22.13,5.229,19.214,6.588,16.587z M27.219,4c1.76,0,3.982,3.139,5.52,8.587
	H21.699C23.237,7.139,25.459,4,27.219,4z M33.654,16.587c0.465,2.558,0.78,5.447,0.875,8.632H19.909
	c0.095-3.185,0.41-6.074,0.875-8.632H33.654z M38.534,25.219c-0.088-2.928-0.369-5.857-0.837-8.632h10.154
	c1.359,2.627,2.231,5.543,2.496,8.632H38.534z M45.231,12.587h-8.369c-0.734-2.904-1.7-5.507-2.89-7.582
	C38.445,6.367,42.348,9.045,45.231,12.587z M20.466,5.005c-1.19,2.076-2.156,4.679-2.89,7.582H9.207
	C12.09,9.045,15.993,6.367,20.466,5.005z M9.208,41.852h8.368c0.734,2.904,1.7,5.506,2.89,7.582
	C15.993,48.071,12.09,45.393,9.208,41.852z M33.972,49.434c1.19-2.076,2.156-4.679,2.89-7.582h8.369
	C42.348,45.394,38.445,48.072,33.972,49.434z"/>
  </svg>
);

export default IconWorldWide;
