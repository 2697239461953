import React from 'react';

const IconArticles = () => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="24.000000pt"
    height="24.000000pt"
    viewBox="0 0 24.000000 24.000000"
    preserveAspectRatio="xMidYMid meet">
    <title>Articles</title>
    <g transform="translate(0.000000,24.000000) scale(0.100000,-0.100000)" stroke="none">
      <path d="M52 207 c-18 -19 -22 -35 -22 -95 l0 -72 70 0 70 0 0 95 0 95 -48 0 c-38 0 -53 -5 -70 -23z m28 -7 c0 -14 -7 -20 -22 -20 -20 0 -21 1 -3 20 10 11 20 20 22 20 1 0 3 -9 3 -20z m48 -47 c-16 -2 -40 -2 -55 0 -16 2 -3 4 27 4 30 0 43 -2 28 -4z m0 -40 c-16 -2 -40 -2 -55 0 -16 2 -3 4 27 4 30 0 43 -2 28 -4z m0 -40 c-16 -2 -40 -2 -55 0 -16 2 -3 4 27 4 30 0 43 -2 28 -4z" />
      <path d="M180 115 l0 -85 -55 0 c-30 0 -55 -4 -55 -10 0 -6 30 -10 70 -10 l70 0 0 95 c0 78 -3 95 -15 95 -12 0 -15 -16 -15 -85z" />
    </g>
  </svg>
);

export default IconArticles;
