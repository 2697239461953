import React from 'react';

const IconVideos = () => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="24.000000pt"
    height="24.000000pt"
    viewBox="0 0 24.000000 24.000000"
    preserveAspectRatio="xMidYMid meet">
    <title>Videos</title>
    <g transform="translate(0.000000,24.000000) scale(0.100000,-0.100000)" stroke="none">
      <path d="M24 197 c-3 -8 -4 -47 -2 -88 l3 -74 95 0 95 0 0 85 0 85 -93 3 c-71 2 -94 0 -98 -11z m109 -56 c40 -20 38 -23 -35 -59 -5 -2 -8 15 -8 38 0 23 3 40 8 38 4 -2 19 -10 35 -17z" />
    </g>
  </svg>
);

export default IconVideos;
