import React from 'react';

const IconCertificates = () => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="512.000000pt"
    height="512.000000pt"
    viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet">
    <title>Certificates</title>
    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
      <path d="M2380 4958 c-96 -88 -202 -184 -235 -214 l-61 -53 -314 34 c-172 19 -315 33 -317 32 -1 -2 -32 -142 -67 -311 -36 -170 -67 -310 -68 -311 -2 -2 -122 -71 -268 -155 -146 -84 -269 -156 -273 -160 -5 -4 49 -135 119 -291 70 -156 127 -289 127 -294 0 -6 -57 -138 -127 -295 -119 -264 -126 -285 -110 -297 10 -7 133 -79 273 -159 141 -81 257 -148 259 -149 1 -1 31 -141 67 -310 36 -170 67 -310 69 -312 2 -2 133 10 292 27 159 17 301 31 315 33 22 2 69 -37 263 -211 l236 -213 236 213 c194 174 241 213 263 211 14 -2 156 -16 315 -33 159 -17 290 -29 292 -27 2 2 33 142 69 312 36 169 66 309 67 310 2 1 118 68 259 149 140 80 263 152 273 159 16 12 9 33 -110 297 -70 157 -127 289 -127 295 0 5 57 138 127 294 70 156 124 287 119 291 -4 4 -127 76 -273 160 -146 84 -266 153 -268 155 -1 1 -32 141 -68 311 -35 169 -66 309 -67 311 -2 1 -145 -13 -318 -32 l-314 -34 -25 23 c-245 222 -450 406 -452 405 -2 0 -82 -73 -178 -161z m342 -563 c340 -45 655 -252 839 -549 107 -174 159 -349 166 -563 6 -166 -5 -261 -48 -400 -110 -353 -388 -638 -744 -761 -228 -78 -522 -78 -750 0 -417 144 -723 510 -786 939 -18 125 -7 343 24 460 104 396 387 699 776 829 164 55 337 70 523 45z" />
      <path d="M2413 4096 c-359 -69 -636 -336 -708 -682 -19 -92 -19 -268 0 -358 60 -285 259 -522 528 -631 504 -203 1069 98 1182 631 19 90 19 266 0 358 -66 319 -323 586 -640 667 -103 26 -268 33 -362 15z" />
      <path d="M1845 1450 c-33 -4 -158 -18 -277 -31 l-217 -22 -205 -437 c-114 -240 -206 -441 -206 -448 0 -9 76 -12 318 -12 l319 0 208 -245 c114 -135 211 -245 214 -245 4 0 112 224 241 498 128 273 234 500 236 503 2 3 -107 105 -241 227 -274 249 -241 231 -390 212z" />
      <path d="M2885 1239 c-132 -121 -239 -225 -237 -232 7 -28 466 -997 473 -997 3 0 100 110 214 245 l208 245 319 0 c242 0 318 3 318 12 0 7 -93 209 -206 450 -161 341 -211 438 -225 438 -10 0 -143 14 -296 30 -152 17 -289 30 -303 29 -18 0 -89 -59 -265 -220z" />
    </g>
  </svg>
);

export default IconCertificates;
