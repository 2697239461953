import React from 'react';

const IconReload = () => (
  <svg viewBox="0 0 512 512" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Reload</title>
    <g id="icon-reload-id" strokeWidth="1">
      <g id="Combined-Shape" transform="translate(64.000000, 64.000000)">
        <path d="M234.666667,149.333333 L234.666667,106.666667 L314.564847,106.664112 C287.579138,67.9778918 242.745446,42.6666667 192,42.6666667 C109.525477,42.6666667 42.6666667,109.525477 42.6666667,192 C42.6666667,274.474523 109.525477,341.333333 192,341.333333 C268.201293,341.333333 331.072074,284.258623 340.195444,210.526102 L382.537159,215.817985 C370.807686,310.617565 289.973536,384 192,384 C85.961328,384 1.42108547e-14,298.038672 1.42108547e-14,192 C1.42108547e-14,85.961328 85.961328,1.42108547e-14 192,1.42108547e-14 C252.316171,1.42108547e-14 306.136355,27.8126321 341.335366,71.3127128 L341.333333,1.42108547e-14 L384,1.42108547e-14 L384,149.333333 L234.666667,149.333333 Z">

        </path>
      </g>
    </g>
  </svg>
);

export default IconReload;
