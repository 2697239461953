import React from 'react';

const IconEmail = () => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="50.000000pt"
    height="50.000000pt"
    viewBox="0 0 50.000000 50.000000"
    preserveAspectRatio="xMidYMid meet">
    <title>Email</title>
    <g transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)" stroke="none">
      <path d="M0 250 l0 -180 250 0 250 0 0 180 0 180 -250 0 -250 0 0 -180z m480 147 c0 -13 -213 -187 -229 -187 -16 0 -226 170 -229 186 -3 12 32 14 227 14 182 0 231 -3 231 -13z m-230 -217 c3 0 56 41 118 90 l112 91 0 -136 0 -135 -230 0 -230 0 0 135 0 136 112 -91 c62 -49 115 -90 118 -90z" />
    </g>
  </svg>
);

export default IconEmail;
